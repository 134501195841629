// extracted by mini-css-extract-plugin
export var servicesTechStack__item = "f5_Qp";
export var servicesTechStack__itemWrapper = "f5_Qn";
export var servicesTechStack__name = "f5_Qq";
export var servicesTechStack__name_chromedevtools = "f5_Qy";
export var servicesTechStack__name_digitalocean = "f5_Qw";
export var servicesTechStack__name_elasticsearch = "f5_Qt";
export var servicesTechStack__name_gitlab = "f5_Qx";
export var servicesTechStack__name_googleCloudPlatform = "f5_QB";
export var servicesTechStack__name_googleDocs = "f5_Qz";
export var servicesTechStack__name_googleanalytics = "f5_Qr";
export var servicesTechStack__name_prometheus = "f5_Qs";
export var servicesTechStack__name_ror = "f5_Qv";