// extracted by mini-css-extract-plugin
export var discoverWorksItem = "cY_v8";
export var discoverWorksItem__arrowLink = "cY_wm";
export var discoverWorksItem__bg = "cY_wc";
export var discoverWorksItem__clientImg = "cY_wq";
export var discoverWorksItem__clientName = "cY_wr";
export var discoverWorksItem__clientPosition = "cY_ws";
export var discoverWorksItem__gradientAfricar = "cY_wH";
export var discoverWorksItem__gradientBabel = "cY_wL";
export var discoverWorksItem__gradientCaker = "cY_wz";
export var discoverWorksItem__gradientDv = "cY_wG";
export var discoverWorksItem__gradientImpact = "cY_wv";
export var discoverWorksItem__gradientIx = "cY_wF";
export var discoverWorksItem__gradientMalleni = "cY_ww";
export var discoverWorksItem__gradientPmk = "cY_wC";
export var discoverWorksItem__gradientPmkDesktop = "cY_wD";
export var discoverWorksItem__gradientRew = "cY_wt";
export var discoverWorksItem__gradientTimebot = "cY_wK";
export var discoverWorksItem__gradientTradeaboat = "cY_wx";
export var discoverWorksItem__gradientTradervs = "cY_wy";
export var discoverWorksItem__gradientVaria = "cY_wJ";
export var discoverWorksItem__gradientWowner = "cY_wB";
export var discoverWorksItem__header = "cY_v9";
export var discoverWorksItem__imageHover = "cY_wb";
export var discoverWorksItem__mobileImage = "cY_wn";
export var discoverWorksItem__results = "cY_wg";
export var discoverWorksItem__results_head = "cY_wh";
export var discoverWorksItem__results_num = "cY_wj";
export var discoverWorksItem__results_text = "cY_wk";
export var discoverWorksItem__results_textOnly = "cY_wl";
export var discoverWorksItem__review = "cY_wp";
export var discoverWorksItem__title = "cY_wd";
export var discoverWorksItem__wrapper = "cY_wf";