// extracted by mini-css-extract-plugin
export var benefitsCustomSoftwareSection = "J_g1";
export var businessCustomSoftwareSection = "J_gZ";
export var caseStudyCustomSoftwareSection = "J_g3";
export var casesCustomSoftwareSection = "J_g5";
export var clientQuotesCustomSoftwareSection = "J_hd";
export var customSoftwareProjLogoSection = "J_g7";
export var expertiseCustomSoftwareSection = "J_g8";
export var industriesCustomSoftwareSection = "J_gY";
export var ourAchievementsCustomSoftwareSection = "J_hc";
export var processCustomSoftwareSection = "J_g0";
export var requirementCustomSoftwareSection = "J_g4";
export var servicesCustomSoftwareSection = "J_gX";
export var techStackCustomSoftwareSection = "J_g2";
export var testimonialsCustomSoftwareSection = "J_g9";
export var testimonialsCustomSoftwareSection__testimonial = "J_hb";
export var whyChooseCustomSoftwareSection = "J_g6";